import React from "react";
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import {HTMLContent} from "../components/Content";
import SimplePageTemplate from "./simple-page-template";
import useSiteMetadata from "../components/SiteMetadata";
import './pricing-page.scss';

const PricingPage = ({data, location}) => {
    const {frontmatter, html} = data.markdownRemark
    const {title} = useSiteMetadata()

    const meta = {title: `Koszty | ${title}`}

    return (
        <Layout location={location} meta={meta}>
            <SimplePageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                subheading={frontmatter.subheading}
                contentTitle={frontmatter.contentTitle}
                contentBody={html}
                contentClass={"pricing-page"}
                contentComponent={HTMLContent}
            />
        </Layout>
    )
}

export default PricingPage;

export const pageQuery = graphql`
    query PricingPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "pricing-page" } }) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                }
                subheading
                contentTitle
            }
            html
        }
    }
`
